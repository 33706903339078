<template>
	<ValidationForm #default="{handleSubmit}">
		<Modal width="700px">
			<template #modal-title>
				Field Filter
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<div
						class="d-flex-flex-row cassie-horizontal-sm"
					>
						<TextField
							:label="'Field' | useLabels"
							:value="queryBuilderItem.fieldName"
							disabled
							class="cassie-input-width-md"
						/>
					</div>
					<div class="d-flex-flex-row cassie-horizontal-sm">
						<Dropdown
							v-model="query.operator"
							:label="'Operator' | useLabels"
							:items="operatorItems"
							class="cassie-input-width-xl pb-4"
							custom-sort
							@change="onOperatorChanged($event)"
						/>
					</div>
				</div>
				<Message
					v-if="query.operator === 'LIKE' || query.operator === 'NOT LIKE'"
					type="warning"
				>
					At least one wildcard (%) must be included in the value field.
				</Message>
				<template
					v-if="operatorToDisplay === 'BETWEEN' || operatorToDisplay === 'NOT BETWEEN'"
				>
					<template
						v-if="typeToDisplay === 'Date'"
					>
						<div
							class="d-flex-flex-row cassie-horizontal-sm cassie-vertical-md"
						>
							<div class="cassie-input-width-md">
								<DatePicker
									:date="formatDate(query.values[0].value)"
									required-date
									@update:date="query.values[0].value = $event"
								/>
							</div>
							<div class="cassie-input-width-md">
								<DatePicker
									:date="formatDate(query.values[1].value)"
									:min="formatDate(query.values[0].value)"
									required-date
									@update:date="query.values[1].value = $event"
								/>
							</div>
						</div>
					</template>
					<template
						v-if="typeToDisplay === 'Number'"
					>
						<div
							class="d-flex-flex-row cassie-horizontal-sm cassie-vertical-md"
						>
							<TextField
								v-model="query.values[0].value"
								:label="'Value 1'"
								class="cassie-input-width-md"
								:rules="{required: true, numeric: true}"
								type="number"
							/>
							<TextField
								v-model="query.values[1].value"
								:label="'Value 2'"
								class="cassie-input-width-md"
								:rules="{required: true, numeric: true}"
								type="number"
							/>
						</div>
					</template>
				</template>
				<template
					v-if="operatorToDisplay === '=' || operatorToDisplay === '<>' || operatorToDisplay === 'LIKE' || operatorToDisplay === 'NOT LIKE' || operatorToDisplay === '>' || operatorToDisplay === '<' || operatorToDisplay === '>=' || operatorToDisplay === '<='"
				>
					<template
						v-if="typeToDisplay === 'Date'"
					>
						<div class="cassie-input-width-md">
							<DatePicker
								:date="formatDate(query.values[0].value)"
								required-date
								@update:date="query.values[0].value = $event"
							/>
						</div>
					</template>
					<template
						v-if="typeToDisplay === 'Number'"
					>
						<TextField
							v-model="query.values[0].value"
							:label="'Value' | useLabels"
							class="cassie-input-width-md"
							rules="required"
							type="number"
						/>
					</template>
					<template
						v-if="typeToDisplay === 'Text' || typeToDisplay === 'GUID'"
					>
						<TextField
							v-model="query.values[0].value"
							:label="'Value' | useLabels"
							class="cassie-input-width-md"
							rules="required"
							type="text"
						/>
					</template>
				</template>
				<template
					v-if="operatorToDisplay === 'IN' || operatorToDisplay === 'NOT IN'"
				>
					<div
						v-for="(value, index) in query.values"
						:key="index"
						class="d-flex flex-row pb-3"
					>
						<TextField
							v-if="typeToDisplay === 'Text' || typeToDisplay === 'GUID'"
							v-model="query.values[index].value"
							:label="('Value ' + [index+1])"
							class="cassie-input-width-md"
							rules="required"
							type="text"
						/>
						<TextField
							v-if="typeToDisplay === 'Number'"
							v-model="query.values[index].value"
							:label="('Value ' + [index+1])"
							class="cassie-input-width-md"
							rules="required"
							type="number"
						/>
						<IconButton
							v-if="index > 0 || query.values.length > 1"
							@click="removeField(index)"
						>
							mdi-trash-can
						</IconButton>
					</div>
					<div
						v-if="showAddFieldButton"
						class="pb-3"
					>
						<SecondaryActionButton
							@click="addField"
						>
							Add Field
						</SecondaryActionButton>
					</div>
				</template>
			</template>

			<template #modal-footer>
				<SecondaryActionButton @click="$emit('cancel')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(confirmFilter)"
				>
					confirm
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import { format } from 'date-fns'
import { DATE_FORMAT_DATE_PICKER } from '../../../../../../shared/utils/date-formatting.js'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import DatePicker from '../../../../../../shared/components/date-picker.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import Message from '../../../../../../shared/components/message.vue'

export default {
	name: 'query-builder-modal',
	components: {
		Dropdown,
		TextField,
		DatePicker,
		SecondaryActionButton,
		PrimaryActionButton,
		Modal,
		ValidationForm,
		IconButton,
		Message
	},
	props: {
		queryBuilderItem: {
			type: Object,
			required: true
		},
		outputFilters: {
			type: Array,
			required: true
		}
	},
	data () {
		return {
			query: {
				operator: '',
				operatorText: '',
				values: [{ value: null }]
			},
			showAddFieldButton: false
		}
	},
	computed: {
		typeToDisplay () {
			return this.queryBuilderItem.typeFriendlyName
		},
		operatorToDisplay () {
			return this.query.operator
		},
		bitItemValues () {
			return [
				{ value: '1', text: 'true' },
				{ value: '0', text: 'false' }
			]
		},
		operatorItems () {
			return this.outputFilters
				.filter(filter => filter.typeFriendlyName === this.queryBuilderItem.typeFriendlyName)
				.map(filter => ({
					value: filter.operator,
					text: filter.friendlyOperator
				}))
		}
	},
	created () {
		if (this.queryBuilderItem.filter) {
			this.query = this.queryBuilderItem.filter
		}
		this.showOrHideAddFieldButton()
	},
	methods: {
		confirmFilter () {
			this.query.operatorText = this.operatorItems.find(item => item.value === this.query.operator).text
			if (['IS', 'IS NOT'].includes(this.query.operator)) {
				this.query.values = [{ value: 'NULL' }]
			}
			this.$emit('confirm', this.query)
		},
		onOperatorChanged (operator) {
			if (operator !== 'IS' && operator !== 'IS NOT') {
				if (this.query.values[0].value === 'NULL') {
					this.query.values = [{ value: null }]
				}
			}
			const fieldsNeeded = operator === 'BETWEEN' || operator === 'NOT BETWEEN' ? 2 : 1
			if (this.query.values.length > fieldsNeeded) {
				this.query.values.splice(fieldsNeeded)
			}
			while (this.query.values.length < fieldsNeeded) {
				this.query.values.push({ value: null })
			}
			this.query.operator = operator
			this.showOrHideAddFieldButton()
		},
		showOrHideAddFieldButton () {
			if (this.query.operator === 'IN' || this.query.operator === 'NOT IN') {
				this.showAddFieldButton = true
			} else {
				this.showAddFieldButton = false
			}
		},
		addField () {
			this.query.values.push({ value: null })
			this.showOrHideAddFieldButton()
		},
		removeField (index) {
			this.query.values.splice(index, 1)
		},
		formatDate (date) {
			if (!date) {
				return null
			}
			return format(new Date(date), DATE_FORMAT_DATE_PICKER)
		}
	}
}
</script>
