<template>
	<validation-observer
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Data Types
			</template>
			<template #body>
				<Dropdown
					label="Data Type *"
					:value.sync="cassieObjectId"
					:items="dataTypeItems"
					rules="required"
					class="cassie-input-width-md"
					tooltip-text="Select the Cassie Data object this service relates to"
					:disabled="!userFullPermissions"
					@input="$emit('update:cassieObjectId', $event)"
					@change="clearFields()"
				/>
				<div class="d-flex flex-row cassie-horizontal-lg">
					<Dropdown
						label="Increment Field *"
						:value="objectIdColumn"
						:items="incrementFieldItems"
						rules="required"
						class="cassie-input-width-md"
						tooltip-text="Choose an appropriate data field within the Cassie object to be used for incremental transfers. Each transfer will send any new information within Cassie, based on this field"
						:disabled="!userFullPermissions"
						@input="$emit('update:objectIdColumn', $event)"
					/>
					<Toggle
						v-if="isEdit"
						class="mr-2"
						:value="resetIncrementalValue"
						label="Reset Incremental Value"
						tooltip-text="Choose an appropriate data field within the Cassie object to be used for incremental transfers. Each transfer will send any new information within Cassie, based on this field"
						:disabled="!userFullPermissions"
						@update:value="$emit('update:resetIncrementalValue', $event)"
					/>
				</div>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Data Fields
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<Dropdown
						v-model="selectedFields"
						label="Fields"
						:items="cassieDataFields"
						multiple
						custom-sort
						persistent-hint
						:disabled="!userFullPermissions"
						class="cassie-input-width-md"
					/>
					<PrimaryActionButton
						v-if="userFullPermissions"
						@click="addFields"
					>
						Add
					</PrimaryActionButton>
				</div>
				<DataTable
					:headers="tableHeaders"
					:items="tableItems"
					:items-per-page="tableItems.length"
					hide-default-footer
				>
					<template #item.filter="{ item }">
						<TextField
							v-if="item.isFilterable"
							view-only
							:value="item.displayFilter"
							label="Filter"
							:clearable="userFullPermissions"
							class="my-2"
							@click:clear="clearDisplayFilter(item)"
							@click="handleShowQueryBuilder(item)"
						/>
						<TextField
							v-else
							label="Filters cannot be applied to this field"
							disabled
							class="my-2"
						/>
					</template>
					<template #item.action="{ index }">
						<IconButton
							tooltip-text="Remove Data Field"
							:disabled="!userFullPermissions"
							@click="removeDataField(index)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Enable Integration Service
			</template>
			<template #body>
				<Toggle
					class="mr-2"
					:value="isEnabled"
					label="Enable Integration Service"
					:disabled="!userFullPermissions"
					tooltip-text="Once enabled, any actions will commence. Only set to enabled and save changes when you are happy with all of the configured settings"
					@update:value="$emit('update:isEnabled', $event)"
				/>
			</template>
		</SectionCard>
		<IntegrationServiceQueryBuilder
			v-if="showQueryBuilder && userFullPermissions"
			:query-builder-item="queryBuilderItem"
			:output-filters="outputFilters"
			@cancel="showQueryBuilder = false"
			@confirm="handleConfirmQueryBuilder($event)"
		/>
	</validation-observer>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import IntegrationServiceQueryBuilder from './integration-service-query-builder.vue'

export default {
	components: { Dropdown, SectionCard, PrimaryActionButton, IconButton, Toggle, TextField, DataTable, IntegrationServiceQueryBuilder },
	props: {
		isEdit: Boolean,
		cassieObjectId: Number,
		objectIdColumn: String,
		resetIncrementalValue: Boolean,
		updateStandDak: Boolean,
		objectSelectList: [Array, String],
		isEnabled: Boolean,
		cassieObjects: Array,
		dataTypeItems: Array,
		targetSystemActionItems: Array,
		userFullPermissions: Boolean,
		outputFilters: Array,
		integrationFilters: Array
	},
	data () {
		const CONTACTS_DATA_TYPE = 1
		const PREFERENCES_DATA_TYPE = 2
		return {
			selectedFields: [],
			CONTACTS_DATA_TYPE,
			PREFERENCES_DATA_TYPE,
			showQueryBuilder: false,
			queryBuilderItem: {
				fieldName: '',
				typeFriendlyName: '',
				filter: {
					operator: null,
					values: []
				}
			},
			filters: [{
				fieldName: '',
				filter: {
					operator: null,
					values: []
				}
			}]
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),

		incrementFieldItems () {
			return this.cassieObjects.find(({ cassieObjectId }) => cassieObjectId === this.cassieObjectId)?.cassieObjectIncrementalFields
		},
		cassieDataFields () {
			const foundCassieObjects = this.cassieObjects.find(({ cassieObjectId }) => cassieObjectId === this.cassieObjectId)?.cassieObjectFields
			let foundItems = foundCassieObjects?.map(field => ({
				value: field.cassieObjectFieldName,
				text: field.cassieObjectFieldName
			}))

			foundItems = foundItems?.filter(({ value }) => !this.objectSelectList.includes(value))
			return foundItems
		},
		tableItems () {
			return this.objectSelectList.map(fieldName => {
				const object = this.cassieObjects.find(({ cassieObjectId }) => cassieObjectId === this.cassieObjectId)?.cassieObjectFields.find(f => f.cassieObjectFieldName === fieldName)
				const filter = this.integrationFilters.find(f => f.fieldName === fieldName)?.filter
				return {
					fieldName,
					filter: filter || '',
					displayFilter: this.formatFilter(filter),
					isIncremental: object?.isIncremental || false,
					isFilterable: object?.isFilterable || false,
					systemTypeName: object?.systemTypeName || '',
					typeFriendlyName: object?.typeFriendlyName || ''
				}
			})
		},
		tableHeaders () {
			return [
				{ text: 'Field', value: 'fieldName', width: '15%' },
				{ text: 'Filter', value: 'filter', width: '65%' },
				{ text: 'Data Type', value: 'typeFriendlyName', width: '15%' },
				{ text: 'Action', value: 'action', width: '5%' }
			]
		}
	},
	methods: {
		updateObjectSelectList (objectSelectList) {
			this.$emit('update:objectSelectList', objectSelectList)
		},
		removeDataField (index) {
			const objectSelectList = [...this.objectSelectList]
			this.$delete(objectSelectList, index)
			this.updateObjectSelectList(objectSelectList)
		},
		addFields () {
			this.updateObjectSelectList([
				...this.objectSelectList,
				...this.selectedFields
			])
			this.selectedFields = []
		},
		displayResponseCode (index) {
			return this.successResponses[index]?.expectedSuccessResponseCode
		},
		displayResponseMessage (index) {
			return this.successResponses[index]?.expectedSuccessResponseMessage
		},
		clearDisplayFilter (item) {
			const updatedFilters = this.integrationFilters.filter(filter => filter.fieldName !== item.fieldName)
			this.$emit('update:filters', updatedFilters)
		},
		handleShowQueryBuilder (item) {
			this.queryBuilderItem = {
				fieldName: item.fieldName,
				typeFriendlyName: item.typeFriendlyName,
				filter: JSON.parse(JSON.stringify(item.filter))
			}

			this.showQueryBuilder = true
		},
		handleConfirmQueryBuilder (query) {
			const fieldName = this.queryBuilderItem.fieldName
			const newFilter = {
				operator: query.operator,
				values: query.values
			}
			let found = false
			const updatedFilters = this.integrationFilters.map(filter => {
				if (filter.fieldName === fieldName) {
					found = true
					return { ...filter, filter: newFilter }
				}
				return filter
			})
			if (!found) {
				updatedFilters.push({
					fieldName: fieldName,
					filter: newFilter
				})
			}
			this.$emit('update:filters', updatedFilters)
			this.showQueryBuilder = false
		},
		formatFilter (filter) {
			if (!filter || !filter.operator) return ''

			const values = filter.values.map(v => v.value)

			switch (filter.operator) {
				case 'IN':
					return `Equal to ${values.join(' or ')}`
				case 'NOT IN':
					return `Not equal to ${values.join(' or ')}`
				case 'BETWEEN':
					if (values.length === 2) {
						return `Between ${values[0]} and ${values[1]}`
					}
					return 'Invalid range'
				case 'NOT BETWEEN':
					if (values.length === 2) {
						return `Not between ${values[0]} and ${values[1]}`
					}
					return 'Invalid range'
				case '<>':
					return `Not equal to ${values[0]}`
				case '=':
					return `Equal to ${values[0]}`
				case 'LIKE':
					return `Like '${values[0]}'`
				case 'NOT LIKE':
					return `Not like '${values[0]}'`
				case '<=':
					return `Less than or equal to ${values[0]}`
				case '>=':
					return `Greater than or equal to ${values[0]}`
				case '<':
					return `Less than ${values[0]}`
				case '>':
					return `Greater than ${values[0]}`
				case 'IS NOT':
					return `Is not blank`
				case 'IS':
					return `Is blank`
				default:
					return ``
			}
		},
		clearFields () {
			this.$emit('update:objectSelectList', [])
			this.$emit('update:filters', [])
		}
	}
}
</script>
