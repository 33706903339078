<template>
	<validation-observer
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				HTTP Response Success Codes
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<TextField
						v-model="expectedSuccessResponseCode"
						type="number"
						label="Success HTTP Response Code *"
						tooltip-text="Input the response code provided by your integration system when the transaction is successful e.g. 200, 202"
						:disabled="!userFullPermissions"
					/>
					<TextField
						v-model="expectedSuccessResponseMessage"
						label="Success HTTP Response Message"
						class="cassie-input-width-sm"
						tooltip-text="Optional, input all of or part of the response message provided by your integration when the transaction is successful e.g. OK, Success"
						:disabled="!userFullPermissions"
					/>
					<PrimaryActionButton
						v-if="userFullPermissions"
						:disabled="!expectedSuccessResponseCode"
						@click="addSuccessResponse"
					>
						Add
					</PrimaryActionButton>
				</div>
				<v-simple-table
					v-if="successResponses"
					dense
					light
				>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									scope="col"
								>
									Reponse Code
								</th>
								<th
									class="text-left"
									scope="col"
								>
									Response Message
								</th>
								<th
									v-if="userFullPermissions"
									class="text-right"
									scope="col"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(message, index) in successResponses"
								:key="index"
							>
								<td>
									{{ displayResponseCode(index) }}
								</td>
								<td>
									{{ displayResponseMessage(index) }}
								</td>
								<td
									v-if="userFullPermissions"
									class="text-right"
								>
									<IconButton
										tooltip-text="Remove Success Code"
										@click="removeSuccessResponses(index)"
									>
										mdi-trash-can
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</template>
		</SectionCard>
	</validation-observer>
</template>
<script>
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'

export default {
	components: { TextField, SectionCard, PrimaryActionButton, IconButton },
	props: {
		successResponses: {
			type: Array,
			default: () => []
		},
		userFullPermissions: Boolean
	},
	data () {
		return {
			expectedSuccessResponseCode: null,
			expectedSuccessResponseMessage: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand'])
	},
	methods: {
		updateSuccessResponses (successResponses) {
			this.$emit('update:successResponses', successResponses)
		},
		removeSuccessResponses (index) {
			const successResponses = [...this.successResponses]
			this.$delete(successResponses, index)
			this.updateSuccessResponses(successResponses)
		},
		addSuccessResponse () {
			this.updateSuccessResponses([
				...this.successResponses,
				{
					expectedSuccessResponseCode: this.expectedSuccessResponseCode,
					expectedSuccessResponseMessage: this.expectedSuccessResponseMessage
				}
			])
			this.expectedSuccessResponseCode = null
			this.expectedSuccessResponseMessage = null
		},
		displayResponseCode (index) {
			return this.successResponses[index]?.expectedSuccessResponseCode
		},
		displayResponseMessage (index) {
			return this.successResponses[index]?.expectedSuccessResponseMessage
		}
	}
}
</script>
