<template>
	<validation-form
		#default="{ handleSubmit }"
		tag="div"
		class="d-flex flex-column overflow-y-hidden"
		style="width: 100%;"
	>
		<ViewLayout>
			<template #header-title>
				{{ pageTitle }}
			</template>
			<template #header-caption>
				{{ pageCaption }}
			</template>
			<template #header-after>
				<Stepper
					:key="currentStepKey"
					:step="currentStep"
					:steps="steps"
					:in-progress="!isEdit"
					@update:step="value => {
						handleSubmit(() => {
							updateCurrentStep(value)
						})
						currentStepKey++
					}"
				/>
			</template>
			<template #content>
				<slot name="content" />
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backButtonAction">
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<div class="cassie-horizontal-md d-flex flex-row">
							<SecondaryActionButton
								v-if="currentStep"
								@click="handleSubmit(previous)"
							>
								Previous
							</SecondaryActionButton>
							<PrimaryActionButton
								v-if="currentStep === steps.length - 1 && userFullPermissions"
								:disabled="!integrationServiceHasChanged || !userFullPermissions"
								@click="handleSubmit(goToLastStep)"
							>
								{{ !isEdit ? 'Finish' : 'Save Changes' }}
							</PrimaryActionButton>
							<template v-else-if="currentStep !== steps.length - 1">
								<PrimaryActionButton
									v-if="isEdit"
									:disabled="!integrationServiceHasChanged || !userFullPermissions"
									@click="handleSubmit(goToLastStep)"
								>
									Save Changes
								</PrimaryActionButton>
								<PrimaryActionButton
									@click="handleSubmit(next)"
								>
									Next
								</PrimaryActionButton>
							</template>
							<PrimaryActionButton
								v-else-if="currentStep === steps.length - 2 && !userFullPermissions"
								@click="handleSubmit(next)"
							>
								Next
							</PrimaryActionButton>
						</div>
					</template>
				</PageActionRow>
			</template>
		</ViewLayout>
	</validation-form>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import Stepper from '../../../../../../shared/components/stepper.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import { CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: { ValidationForm, PrimaryActionButton, SecondaryActionButton, PageActionRow, Stepper, ViewLayout },
	props: {
		backButtonAction: Function,
		brandId: Number,
		finishButtonAction: {
			type: Function,
			default: () => {}
		},
		isEdit: Boolean,
		currentStep: Number,
		steps: Array,
		integrationServiceHasChanged: Boolean
	},
	data () {
		return {
			// Needed due to Vuetify using internal state management for isActive property on the tab
			// Otherwise validation check is ignored and the tab selected is changed
			currentStepKey: 0
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		pageTitle () {
			return `${this.isEdit ? 'Edit' : 'Create'} Integration Service`
		},
		pageCaption () {
			return this.isEdit ? 'Edit an existing integration service' : 'create integration service'
		},
		userFullPermissions () {
			return this.productAreaPermission(CONNECTOR_SERVICE_MODULE_FULL_PERMISSIONS)
		}
	},
	methods: {
		async goToLastStep () {
			await this.finishButtonAction()
			const steps = JSON.parse(JSON.stringify(this.steps))
			steps[this.currentStep].stepComplete = true
			this.updateSteps(steps)
			this.updateCurrentStep(this.steps.length - 1)
		},
		updateCurrentStep (currentStep) {
			this.$emit('update:currentStep', currentStep)
		},
		updateSteps (steps) {
			this.$emit('update:steps', steps)
		},
		previous () {
			this.updateCurrentStep(this.currentStep - 1)
		},
		next () {
			const steps = JSON.parse(JSON.stringify(this.steps))
			steps.forEach((step, index) => {
				if (index <= this.currentStep) step.stepComplete = true
			})
			this.updateSteps(steps)
			this.updateCurrentStep(this.currentStep + 1)
		}
	}
}
</script>
